<template>
  <div>
    <div class="breadcrumb_header">
      <div class="breadcrumb ms-2">
        <h4 style="margin: 0; padding-left: 10px">NeXa</h4>
        <span class="divider"></span>
        <span class="breadcrumb-text"
          >Use Cases / Compare Jurisdiction / {{ this.$route.params.id }}</span
        >
      </div>
      <div>
        <button @click="toggleSidebar" class="open_sidebar me-2">
          <span class="material-symbols-rounded icon"
            >keyboard_double_arrow_left</span
          >
          <p>Open Sidebar</p>
        </button>
      </div>
    </div>

    <div class="row">
      <div class="col-md-1 mt-3 p-0 mx-5">
        <div
          class="d-flex justify-content-start cursor-pointer back"
          @click="goBack"
        >
          <span class="material-symbols-rounded" style="margin: 0"
            >arrow_back</span
          >
          <span style="margin: 0 0 0 8px">Back</span>
        </div>
        <nav v-if="!loading" class="link-buttons">
          <button
            class="link-button"
            v-for="(el, index) in jurisdictions"
            :class="{ 'link-button--active': index === selectedJurisdiction }"
            @click="selectedJurisdiction = index"
            :key="el.id"
          >
            <img
              :src="countryImages[countryMapping[el.country]]"
              :alt="`Flag of ${el.country}`"
              class="link-button__icon"
            />
            <span class="link-button__text" v-if="el.country === 'USA'">
              {{ el.jurisdiction }}
            </span>
            <span class="link-button__text" v-else>
              {{ countryMapping[el.country] }}
            </span>
          </button>

          <!-- Uncomment the section below if you want to add an "Add more" button -->
          <!-- 
  <button class="link-button link-button--add">
    <img
      src="https://cdn.builder.io/api/v1/image/assets/TEMP/f3b4d194d2f9ef9118c4568e4266fc1ea14036cbe58ff158fa696d701f3bc443?placeholderIfAbsent=true&apiKey=411e2ec61bdb45ecb3af3338badbc5df"
      alt=""
      class="link-button__icon"
    />
    <span class="link-button__text">Add more</span>
  </button>
  -->
        </nav>
      </div>
      <PageLoader
        style="padding-top: 120px"
        v-if="loading"
        :loading="loading"
        :replace_msg="loading_message"
        :completedPercentage="duration"
        :files_uploaded="files.length"
      />

      <div v-else class="col-md-10 p-0" style="margin-top: 5.5rem">
        <div>
          <section
            class="claims-container"
            v-for="q in filteredQuestions"
            :key="`questions-${q.id}`"
            :id="`q-${q.id}`"
          >
            <FollowUpQuestionBox :q="q" />
          </section>

          <div class="search-bar">
            <div class="search-entry">
              <label for="searchInput" class="visually-hidden"
                >Search query</label
              >
              <span>{{ question }}</span>
            </div>
            <div class="jurisdiction-controls">
              <button type="button" class="jurisdiction-button">
                <img
                  :src="
                    countryImages[
                      countryMapping[
                        jurisdictions?.[selectedJurisdiction]?.country
                      ]
                    ]
                  "
                  alt=""
                  class="jurisdiction-icon"
                />

                <span
                  v-if="jurisdictions[selectedJurisdiction].country === 'USA'"
                >
                  {{ jurisdictions[selectedJurisdiction].jurisdiction }}
                </span>
                <span v-else>
                  {{ jurisdictions[selectedJurisdiction].country }}
                </span>
              </button>
              <button type="button" class="file-button" @click="toggleSidebar">
                View Uploaded Files
              </button>
            </div>
          </div>

          <main class="content">
            <section class="answer-section">
              <div class="answer-header mt-2">
                <div class="tab d-flex gap-2 align-items-center">
                  <h6 class="answer-title">Compare Jurisdiction</h6>
                </div>
                <div class="actions">
                  <button
                    class="button me-2"
                    style="border-width: 0px; color: #0e4485 !important"
                    @click="copy"
                  >
                    <img
                      src="https://cdn.builder.io/api/v1/image/assets/TEMP/4042ce02169404fc8f24e2435141f1518abb04892a209162cf916b7ff7de4ac3?placeholderIfAbsent=true&apiKey=b7b3d02748134357b1767f32084c4983"
                      alt="Copy icon"
                      class="img"
                    />
                    <span>{{ isCopied ? "Copied" : "Copy" }}</span>
                  </button>
                  <b-dropdown
                    size="sm"
                    id="dropdown-right"
                    right
                    offset="-9"
                    variant="link"
                    toggle-class="text-decoration-none"
                    no-caret
                    @click="toggleDropdown"
                  >
                    <template #button-content>
                      <button class="action-button export-button">
                        <span class="button-label">Export</span>
                        <img
                          :class="{ 'rotate-180': isDropdown }"
                          src="https://cdn.builder.io/api/v1/image/assets/TEMP/d12668b437286b44ce443817869f8a85013f194276d09cf30b58481266006963?placeholderIfAbsent=true&apiKey=b7b3d02748134357b1767f32084c4983"
                          alt="Export Icon"
                          class="icon"
                        />
                      </button>
                    </template>

                    <b-dropdown-item class="ms-1 me-1" @click="exportToPDF">
                      <img class="me-2" src="@/assets/icon/pdf-icon.svg" />
                      <span class="dropdown-text">Export as PDF</span>
                    </b-dropdown-item>
                  </b-dropdown>
                </div>
              </div>

              <p class="answer-text">
                Ask a legal question, upload documents, and compare the legal
                outcomes across different jurisdictions.
              </p>
              <hr class="separator" />
            </section>

            <section class="discussion mt-4">
              <div class="tabs mt-4">
                <div class="tab d-flex gap-2 align-items-center">
                  <h6 class="question mt-2">NeXa’s Answer:</h6>
                </div>
                <article id="marked-style" class="answer-text mt-1">
                  <vue-marked>{{ nexaAnswer }}</vue-marked>
                </article>
              </div>
            </section>

            <section class="legal-references">
              <header>
                <h6 class="reference-title">Nexa’s Legal References:</h6>
                <p class="detail-text">
                  Nexa has identified the following resources that may be
                  pertinent to your inquiry:
                </p>
              </header>

              <div class="results w-100">
                <div class="result-tabs">
                  <button
                    @click="activeTab = 'case_laws'"
                    class="tab"
                    :class="{ active: activeTab === 'case_laws' }"
                  >
                    {{ `Case Law (${Object.keys(nexaCaselaws).length})` }}
                  </button>
                  <button
                    @click="activeTab = 'legislations'"
                    class="tab"
                    :class="{ active: activeTab === 'legislations' }"
                  >
                    {{
                      `Statutes & Regulations
                    (${Object.keys(nexaLegislations).length})`
                    }}
                  </button>
                  <!-- <button
                    @click="activeTab = 'administrative'"
                    class="tab"
                    :class="{ active: activeTab === 'administrative' }"
                  >
                    Administrative Decisions (0)
                  </button>
                  <button
                    @click="activeTab = 'secondary'"
                    class="tab"
                    :class="{ active: activeTab === 'secondary' }"
                  >
                    Secondary Sources (0)
                  </button> -->
                  <button
                    @click="activeTab = 'doctrines'"
                    class="tab"
                    :class="{ active: activeTab === 'doctrines' }"
                  >
                    {{ `Doctrines (${nexaDoctrines.length})` }}
                  </button>
                </div>

                <div v-show="activeTab === 'case_laws'">
                  <div v-if="Object.keys(nexaCaselaws).length > 0">
                    <article
                      v-for="(case_law, index) in nexaCaselaws"
                      :key="index"
                      class="case-result m-3"
                    >
                      <h6 class="mb-3">
                        <a
                          :href="case_law.Link"
                          target="_blank"
                          rel="noopener noreferrer"
                          class="link"
                          >{{
                            `${index.replace("C", "")}. ${case_law.Name}`
                          }}</a
                        >
                      </h6>
                      <p class="h6 mb-2">Relevant Keypoints</p>
                      <p class="detail-text m-0">
                        {{ case_law.relevant_keypoints }}
                      </p>
                    </article>
                  </div>
                  <section
                    v-else
                    class="container d-flex flex-column align-items-center text-center"
                  >
                    <img
                      loading="lazy"
                      src="https://cdn.builder.io/api/v1/image/assets/TEMP/ca0e30b6f77d9579cce9d094df0b0ae41a8025719ef75617abe2b78a3b68ce98?placeholderIfAbsent=true&apiKey=e830e5794ba84973b9eb690ba825e1f8"
                      class="img-fluid my-3"
                      alt="No annotations icon"
                      style="width: 150px"
                    />
                    <p class="h6 mb-2">No Case Law Found</p>
                    <p class="text-muted">
                      No case law are available for this case or document. You
                      may want to try a different source or check back later.
                    </p>
                  </section>
                </div>

                <div v-show="activeTab === 'legislations'">
                  <div v-if="Object.keys(nexaLegislations).length > 0">
                    <article
                      v-for="(legislation, index) in nexaLegislations"
                      :key="index"
                      class="case-result m-3"
                    >
                      <h6 class="mb-3">
                        {{ `${index.replace("L", "")}. ${legislation.title}` }}
                      </h6>
                      <p class="mb-2">
                        <a
                          :href="legislation.href"
                          target="_blank"
                          rel="noopener noreferrer"
                          class="link"
                          >{{ legislation.name }}</a
                        >
                      </p>
                      <p class="detail-text m-0">{{ legislation.body }}</p>
                    </article>
                  </div>
                  <section
                    v-else
                    class="container d-flex flex-column align-items-center text-center"
                  >
                    <img
                      loading="lazy"
                      src="https://cdn.builder.io/api/v1/image/assets/TEMP/ca0e30b6f77d9579cce9d094df0b0ae41a8025719ef75617abe2b78a3b68ce98?placeholderIfAbsent=true&apiKey=e830e5794ba84973b9eb690ba825e1f8"
                      class="img-fluid my-3"
                      alt="No annotations icon"
                      style="width: 150px"
                    />
                    <p class="h6 mb-2">No Statutes & Regulations Found</p>
                    <p class="text-muted">
                      No statues & regulations are available for this case or
                      document. You may want to try a different source or check
                      back later.
                    </p>
                  </section>
                </div>

                <div v-show="activeTab === 'doctrines'">
                  <div v-if="nexaDoctrines.length > 0">
                    <article
                      v-for="(doctrine, index) in nexaDoctrines"
                      :key="index"
                      class="case-result m-3"
                    >
                      <h6 class="mb-3">
                        {{ `${index + 1}. ${doctrine.doctrine_name}` }}
                      </h6>
                      <p class="h6 mb-2">Status</p>
                      <p class="detail-text m-0">
                        {{ doctrine.doctrine_status }}
                      </p>
                      <p class="h6 my-2">Recent Finalized Cases:</p>
                      <p class="mb-2">
                        <a
                          :href="doctrine.doctrine_case_link"
                          target="_blank"
                          rel="noopener noreferrer"
                          class="link"
                          >{{ doctrine.doctrine_case }}</a
                        >
                      </p>
                    </article>
                  </div>
                  <section
                    v-else
                    class="container d-flex flex-column align-items-center text-center"
                  >
                    <img
                      loading="lazy"
                      src="https://cdn.builder.io/api/v1/image/assets/TEMP/ca0e30b6f77d9579cce9d094df0b0ae41a8025719ef75617abe2b78a3b68ce98?placeholderIfAbsent=true&apiKey=e830e5794ba84973b9eb690ba825e1f8"
                      class="img-fluid my-3"
                      alt="No annotations icon"
                      style="width: 150px"
                    />
                    <p class="h6 mb-2">No Doctrines Found</p>
                    <p class="text-muted">
                      No doctrines are available for this case or document. You
                      may want to try a different source or check back later.
                    </p>
                  </section>
                </div>

                <!-- <div v-show="activeTab === 'administrative'">
                  <section
                    class="container d-flex flex-column align-items-center text-center"
                  >
                    <img
                      loading="lazy"
                      src="https://cdn.builder.io/api/v1/image/assets/TEMP/ca0e30b6f77d9579cce9d094df0b0ae41a8025719ef75617abe2b78a3b68ce98?placeholderIfAbsent=true&apiKey=e830e5794ba84973b9eb690ba825e1f8"
                      class="img-fluid my-3"
                      alt="No annotations icon"
                      style="width: 150px"
                    />
                    <p class="h6 mb-2">No Administrative Decisions Found</p>
                    <p class="text-muted">
                      No administrative decisions are available for this case or
                      document. You may want to try a different source or check
                      back later.
                    </p>
                  </section>
                </div> -->

                <!-- <div v-show="activeTab === 'secondary'">
                  <section
                    class="container d-flex flex-column align-items-center text-center"
                  >
                    <img
                      loading="lazy"
                      src="https://cdn.builder.io/api/v1/image/assets/TEMP/ca0e30b6f77d9579cce9d094df0b0ae41a8025719ef75617abe2b78a3b68ce98?placeholderIfAbsent=true&apiKey=e830e5794ba84973b9eb690ba825e1f8"
                      class="img-fluid my-3"
                      alt="No annotations icon"
                      style="width: 150px"
                    />
                    <p class="h6 mb-2">No Secondary Sources Found</p>
                    <p class="text-muted">
                      No secondary sources are available for this case or
                      document. You may want to try a different source or check
                      back later.
                    </p>
                  </section>
                </div> -->
              </div>
            </section>
          </main>
        </div>
      </div>
    </div>
    <div v-if="!loading" class="wrapper-chatbox">
      <ChatBox @chat-submit="submitQuery" />
    </div>
    <sidebar
      v-clickaway="toggleSidebar"
      v-if="sidebarCollapsed"
      @toggle-sidebar="toggleSidebar"
      :uploadFiles="files"
    ></sidebar>
  </div>
</template>
<script>
import ChatBox from "../../../components/input/ChatBox.vue";
import PageLoader from "../../PageLoader.vue";
import sidebar from "../sidebar.vue";
import usecase from "@/store/usecase.js";
import nexa from "@/store/nexa.js";
import FollowUpQuestionBox from "../../../components/input/FollowUpQuestionBox.vue";
import countryImages from "@/assets/country/index";
import VueMarked from "vue-marked";
import marked from "marked";
import { ExportDataPDF } from "@/store/utils";
export default {
  components: {
    sidebar,
    VueMarked,
    ChatBox,
    PageLoader,
    FollowUpQuestionBox,
  },
  data() {
    return {
      loading: true,
      loading_message: "Please wait a moment while we process it.",
      duration: 0,
      interval: null,
      sidebarCollapsed: false,
      lawbotVisible: false,
      isCountry: false,
      showSummary: false,
      countryImages,
      files: [],
      questions: [],
      question: "",
      answer: "",
      isDropdown: false,
      countryMapping: {
        Australia: "AUS",
        USA: "USA",
        Canada: "CAN",
        "New Zealand": "NZ",
        Singapore: "SG",
        Malaysia: "MY",
        "United Kingdom": "UK",
      },
      legislations: {},
      case_laws: {},
      discussion: "",
      selectedJurisdiction: 0,
      jurisdictions: [],
      activeTab: "case_laws",
      followUpQuestion: "",
      collapsedQuestions: [],
      isCopied: false,
      isFetching: false,
      showAll: false,
      isDropdownOpen: [],

      optionsContent: {
        modules: {
          toolbar: [
            [{ size: ["small", false, "large", "huge"] }], // custom dropdown
            [{ align: [] }, { list: "ordered" }, { list: "bullet" }],
            [{ color: [] }, { background: [] }],
            ["bold", "italic", "underline", "strike", "code-block"],
            [{ list: "bullet" }, { list: "ordered" }],
          ],
        },
      },
    };
  },
  computed: {
    filteredQuestions() {
      return this.questions.slice(1);
    },
    parsedCaseLaws() {
      return Object.values(this.case_laws);
    },
    parsedLegislations() {
      return Object.values(this.legislations);
    },
    totalCitations() {
      return (
        Object.keys(this.case_laws).length +
        Object.keys(this.legislations).length
      );
    },

    nexaAnswer() {
      return this.jurisdictions[this.selectedJurisdiction]?.nexaDiscussion
        ? JSON.parse(
            this.jurisdictions[this.selectedJurisdiction]?.nexaDiscussion
          )
        : "";
    },

    nexaDoctrines() {
      return this.jurisdictions[this.selectedJurisdiction]?.nexaDoctrines
        ? JSON.parse(
            this.jurisdictions[this.selectedJurisdiction]?.nexaDoctrines
          )
        : [];
    },

    nexaFiles() {
      return this.jurisdictions[this.selectedJurisdiction]?.nexaFiles
        ? JSON.parse(this.jurisdictions[this.selectedJurisdiction]?.nexaFiles)
        : [];
    },

    nexaLegislations() {
      return this.jurisdictions[this.selectedJurisdiction]?.nexaLegislations
        ? JSON.parse(
            this.jurisdictions[this.selectedJurisdiction]?.nexaLegislations
          )
        : {};
    },

    nexaCaselaws() {
      return this.jurisdictions[this.selectedJurisdiction]?.nexaCaselaws
        ? JSON.parse(
            this.jurisdictions[this.selectedJurisdiction]?.nexaCaselaws
          )
        : {};
    },
  },
  methods: {
    async refetchAnswers() {
      if (this.isFetching) return;
      this.isFetching = true;

      const checkAllAnswers = () =>
        this.questions.every((q) => q.answer !== null);

      while (!checkAllAnswers()) {
        const updatedQuestions = await Promise.all(
          this.questions.map(async (element) => {
            if (element.answer == null) {
              try {
                const response = await usecase.GetResult(
                  this.$route.params.id ?? ""
                );

                const updatedQuestion = response.data.data.questions.find(
                  (q) => q.id === element.id
                );

                if (updatedQuestion && updatedQuestion.answer !== null) {
                  return {
                    ...element,
                    answer: updatedQuestion.answer,
                  };
                }
                return element;
              } catch (error) {
                return element;
              }
            }
            return element;
          })
        );

        this.questions = updatedQuestions;

        await new Promise((resolve) => setTimeout(resolve, 2000));
      }
      this.isFetching = false;
    },

    exportToPDF() {
      let content;
      const followUpQuestion = `<h1 style="font-size: 24px; font-weight: bold;">Question: ${this.question}</h1> <br> <hr>`;
      const tempDiv = document.createElement("div");
      tempDiv.style.width = "65%";
      tempDiv.style.maxWidth = "800px";
      tempDiv.style.margin = "20px auto";
      tempDiv.style.padding = "20px";
      tempDiv.style.border = "1px solid #ccc";
      tempDiv.style.borderRadius = "10px";
      tempDiv.style.backgroundColor = "#f9f9f9";
      tempDiv.style.boxShadow = "0 2px 10px rgba(0, 0, 0, 0.1)";
      tempDiv.style.fontFamily = "Arial, sans-serif";

      content = marked(this.nexaAnswer);

      tempDiv.innerHTML =
        followUpQuestion + `<div class="content">${content}</div>`;
      document.body.appendChild(tempDiv);

      ExportDataPDF(
        tempDiv.innerHTML,
        false,
        `Compare Juridictions - ${this.$route.params.id}`
      );
    },
    toggleDropdown(index, state) {
      this.$set(this.isDropdownOpen, index, state);
    },
    toggleShowAll() {
      this.showAll = !this.showAll;
    },
    fetchData() {
      this.refetchAnswers();
    },

    submitQuery(question) {
      let jurisForQuery = {};

      if (this.jurisdictions[this.selectedJurisdiction].country === "USA") {
        jurisForQuery[this.jurisdictions[this.selectedJurisdiction].country] = [
          this.jurisdictions[this.selectedJurisdiction].jurisdiction,
        ];
      } else {
        jurisForQuery[this.jurisdictions[this.selectedJurisdiction].country] = [
          "all",
        ];
      }

      const formData = new FormData();
      formData.append("entity_type", "campaign");
      formData.append("entity_id", this.$route.params.id);
      formData.append("language", "en");
      formData.append("location_id", 1);
      formData.append("question", question);
      formData.append("jurisdiction", JSON.stringify(jurisForQuery));
      this.blockUser = true;
      nexa
        .DetailAsk(formData)
        .then((response) => {
          const responseData = response.data?.data;
          const responseQuestions = responseData?.questions;
          this.refetchAnswers();

          if (responseQuestions && Array.isArray(responseQuestions)) {
            responseQuestions.forEach((q) => {
              const existing = this.questions.find(
                (existing) => existing.id === q.id
              );
              if (existing) {
                existing.answer = q.answer;
              } else {
                this.questions.push(q);
              }
            });

            this.refetchAnswers();

            if (response.data.completedPercentage === 100) {
              this.blockUser = false;
            }
          } else {
            console.error(
              "Error: responseQuestions is undefined or not an array",
              response
            );
          }
        })
        .catch((error) => {
          console.error("Error submitting query:", error);
          this.blockUser = false;
        });
    },
    toggleCollapse(index) {
      const isCollapsedIndex = this.collapsedQuestions.indexOf(index);
      if (isCollapsedIndex >= 0) {
        this.collapsedQuestions.splice(isCollapsedIndex, 1);
      } else {
        this.collapsedQuestions.push(index);
      }
    },
    isCollapsed(index) {
      return this.collapsedQuestions.includes(index);
    },
    viewSummary() {
      this.$router.push({ name: "BuildArgumentSummary" });
    },
    toggleSidebar() {
      this.sidebarCollapsed = !this.sidebarCollapsed;
    },
    goBack() {
      this.$router.push({ name: "ExpertMode" });
    },

    toggleCountry() {
      this.isCountry = !this.isCountry;
    },
    showAttachModal() {
      this.$bvModal.show("attachfile");
    },
    hideAttachModal() {
      this.$bvModal.hide("attachfile");
    },
    copy() {
      const htmlString = marked.parse(this.nexaAnswer);

      const tempDiv = document.createElement("div");
      tempDiv.innerHTML = htmlString;

      const plainText = tempDiv.innerText;

      navigator.clipboard
        .writeText(plainText)
        .then(() => {
          this.$toast.success("Copied as plain text");
          this.isCopied = true;
        })
        .catch(() => {
          this.$toast.error("Error copying the text");
        })
        .finally(() => {
          setTimeout(() => {
            this.isCopied = false;
          }, 2000);
        });
    },

    ExportData(data, question) {
      const followUpQuestion = `<h1 style="font-size: 24px; font-weight: bold;">Follow up Question: ${question}</h1>`;
      let formattedContent =
        `${followUpQuestion}\n<hr style="border:1px solid black;">\n\n` +
        data
          .replace(/(\*\*)|(\*)/g, "")
          .replace(/---+/g, '<hr style="border:1px solid black;">')
          .replace(/### (.*?)\n/g, '<h3 style="font-size: 18px">$1</h3>')
          .replace(/## (.*?)\n/g, '<h2 style= "font-size: 20px">$1</h2>')
          .replace(/# (.*?)\n/g, '<h1 style="font-size: 22px">$1</h1>')
          .replace(/#/g, "")
          .replace(/(\r\n|\n|\r)/g, "<br>")
          .replace(/(\d+)\.\s*(.*?)<br>/g, "<strong>$1. $2</strong><br>")
          .replace(/- (.*?)\n/g, "<li>$1</li>")
          .replace(/<li>(.*?)<\/li>/g, "<ul><li>$1</li></ul>");
      ExportDataPDF(formattedContent);
      this.$toast.success("Successfully downloaded PDF");
    },
    ExportDiscussion(data) {
      data
        .replace(/(\*\*)|(\*)/g, "")
        .replace(/---+/g, '<hr style="border:1px solid black;">')
        .replace(/### (.*?)\n/g, '<h3 style="font-size: 18px">$1</h3>')
        .replace(/## (.*?)\n/g, '<h2 style= "font-size: 20px">$1</h2>')
        .replace(/# (.*?)\n/g, '<h1 style="font-size: 22px">$1</h1>')
        .replace(/#/g, "")
        .replace(/(\r\n|\n|\r)/g, "<br>")
        .replace(/(\d+)\.\s*(.*?)<br>/g, "<strong>$1. $2</strong><br>")
        .replace(/- (.*?)\n/g, "<li>$1</li>")
        .replace(/<li>(.*?)<\/li>/g, "<ul><li>$1</li></ul>");
      ExportDataPDF(data);
      this.$toast.success("Successfully downloaded PDF");
    },
    handleSubmit() {
      if (this.followUpQuestion.length > 0) {
        const payload = {
          language: "en",
          location_id: 1,
          question: this.followUpQuestion,
        };

        nexa
          .DetailAsk(this.$route.params.id, payload)
          .then((response) => {
            const newResponse = response.data.data;
            console.log(newResponse);
            this.followUpQuestion = "";
            this.$toast.success("Successful");
          })
          .catch((error) => {
            console.error("Error sending question:", error);
          });
      }
    },
    formatDateTime(date) {
      return new Date(date).toISOString();
    },
    getTimeDifference(date) {
      const now = new Date();
      const createdAt = new Date(date);
      const diffInMinutes = Math.floor((now - createdAt) / (1000 * 60));

      if (diffInMinutes < 60) {
        return `${diffInMinutes} m`;
      } else {
        const diffInHours = Math.floor(diffInMinutes / 60);
        return `${diffInHours} h`;
      }
    },
    extractValuesByKey(values, key) {
      return values.find((value) => value.key === key)?.value || null;
    },
  },
  beforeDestroy() {
    clearInterval(this.interval);
  },
  created() {
    usecase.GetResult(this.$route.params.id ?? "").then((response) => {
      const DATA = response.data.data;
      if (DATA.completedPerCentage >= 100) {
        this.duration = DATA.completedPerCentage;
        setTimeout(() => {
          this.loading = false;
        }, 1000);
      }

      if (DATA.completedPerCentage < 100) {
        if (DATA.completedPerCentage >= 0) {
          this.duration = DATA.completedPerCentage;
          this.files = DATA.questionDocuments;
        }

        this.interval = setInterval(() => {
          usecase.GetResult(this.$route.params.id).then((res) => {
            if (res.data.data.completedPerCentage >= 100) {
              this.duration = res.data.data.completedPerCentage;
              clearInterval(this.interval);
              location.reload();
            } else if (res.data.data.completedPerCentage > 0) {
              this.duration = res.data.data.completedPerCentage;
            }
          });
        }, 10000);
      } else {
        this.duration = 100;
        DATA.jurisdictionLocations.forEach((country) => {
          if (country.name === "USA") {
            let valueIndex = 0;

            country.jurisdictions.forEach((jurisdiction) => {
              const numValues = 4;

              const jurisdictionValues = country.values.slice(
                valueIndex,
                valueIndex + numValues
              );

              const jurisdictionData = {
                country: country.name,
                jurisdiction: jurisdiction,
                nexaDiscussion: this.extractValuesByKey(
                  jurisdictionValues,
                  "nexa_discussion"
                ),
                nexaDoctrines: this.extractValuesByKey(
                  jurisdictionValues,
                  "nexa_doctrines"
                ),
                nexaCaselaws: this.extractValuesByKey(
                  jurisdictionValues,
                  "nexa_caselaws"
                ),
                nexaLegislations: this.extractValuesByKey(
                  jurisdictionValues,
                  "nexa_legislations"
                ),
                nexaFiles: this.extractValuesByKey(
                  jurisdictionValues,
                  "nexa_files"
                ),
              };

              this.jurisdictions.push(jurisdictionData);

              valueIndex += numValues;
            });
          } else {
            country.jurisdictions.forEach((jurisdiction) => {
              this.jurisdictions.push({
                country: country.name,
                jurisdiction: jurisdiction.name,
                nexaDiscussion: this.extractValuesByKey(
                  country.values,
                  "nexa_discussion"
                ),
                nexaDoctrines: this.extractValuesByKey(
                  country.values,
                  "nexa_doctrines"
                ),
                nexaCaselaws: this.extractValuesByKey(
                  country.values,
                  "nexa_caselaws"
                ),
                nexaLegislations: this.extractValuesByKey(
                  country.values,
                  "nexa_legislations"
                ),
                nexaFiles: this.extractValuesByKey(
                  country.values,
                  "nexa_files"
                ),
              });
            });
          }
        });

        this.files = DATA.questionDocuments;
        this.question = DATA.questions[0].question;
        this.answer = DATA.questions[0].answer;
        this.questions = DATA.questions || [];
        const hasNullValue = this.questions.some((q) => q.answer === null);
        if (hasNullValue) {
          this.refetchAnswers();
        }
        this.collapsedQuestions = this.questions.map((_, index) => index);
      }
    });
  },
};
</script>

<style scoped>
#marked-style ::v-deep(div > * > strong) {
  font-weight: 600 !important;
}

#marked-style ::v-deep(div > * > b) {
  font-weight: 600 !important;
}

#marked-style ::v-deep(div h1 *) {
  font-size: 2.25rem !important;
  line-height: 2.5rem !important;
  font-weight: 600 !important;
}

#marked-style ::v-deep(div h2 *) {
  font-size: 1.875rem !important;
  line-height: 2.25rem !important;
  font-weight: 600 !important;
}

#marked-style ::v-deep(div h3 *) {
  font-size: 1.5rem !important;
  line-height: 2rem !important;
  font-weight: 600 !important;
}

#marked-style ::v-deep(div p *) {
  font-size: 1rem !important;
  line-height: 1.75rem !important;
  font-weight: bold !important;
  color: #374151 !important;
}

#marked-style ::v-deep(div a *) {
  color: #3b82f6 !important;
  text-decoration: underline !important;
  font-weight: bold !important;
}

#marked-style ::v-deep(div ul *) {
  font-size: inherit !important;
  font-weight: bold !important;
}

#marked-style ::v-deep(div ol *) {
  font-size: inherit !important;
  font-weight: bold !important;
}

#marked-style ::v-deep(div li *) {
  font-size: inherit !important;
  font-weight: bold !important;
}

#marked-style ::v-deep(div blockquote strong) {
  font-size: 1.125rem !important;
  line-height: 1.75rem !important;
  font-weight: bold !important;
  color: #6b7280 !important;
}

#marked-style ::v-deep(div pre strong) {
  font-size: inherit !important;
  font-weight: bold !important;
  color: #d97706 !important;
}

#marked-style ::v-deep(div code strong) {
  font-size: 0.875rem !important;
  font-weight: bold !important;
  background-color: #f3f4f6 !important;
  color: #d97706 !important;
}

#marked-style ::v-deep(div h1) {
  font-size: 2.25rem !important;
  line-height: 2.5rem !important;
  font-weight: 700 !important;
  margin-bottom: 2rem !important;
  margin-top: 2rem !important;
}

#marked-style ::v-deep(div h2) {
  font-size: 1.875rem !important;
  line-height: 2.25rem !important;
  font-weight: 600 !important;
  margin-bottom: 1.5rem !important;
  margin-top: 1.5rem !important;
}

#marked-style ::v-deep(div h3) {
  font-size: 1.5rem !important;
  line-height: 2rem !important;
  font-weight: 500 !important;
  margin-bottom: 1.25rem !important;
  margin-top: 1.25rem !important;
}

#marked-style ::v-deep(div p) {
  font-size: 1rem !important;
  line-height: 1.75rem !important;
  margin-bottom: 1rem !important;
  color: #374151 !important;
}

#marked-style ::v-deep(div a) {
  color: #3b82f6 !important;
  text-decoration: underline !important;
}

#marked-style ::v-deep(div ul) {
  list-style-type: disc !important;
  margin-left: 1.25rem !important;
  margin-bottom: 1rem !important;
}

#marked-style ::v-deep(div ol) {
  list-style-type: decimal !important;
  margin-left: 1.25rem !important;
  margin-bottom: 1rem !important;
}

#marked-style ::v-deep(div li) {
  margin-bottom: 0.5rem !important;
}

#marked-style ::v-deep(div blockquote) {
  font-size: 1.125rem !important;
  line-height: 1.75rem !important;
  color: #6b7280 !important;
  border-left: 4px solid #d1d5db !important;
  padding-left: 1rem !important;
  margin-bottom: 1rem !important;
}

#marked-style ::v-deep(div pre) {
  background-color: #f3f4f6 !important;
  padding: 1rem !important;
  border-radius: 0.375rem !important;
  overflow-x: auto !important;
  margin-bottom: 1rem !important;
}

#marked-style ::v-deep(div code) {
  background-color: #f3f4f6 !important;
  padding: 0.25rem !important;
  border-radius: 0.25rem !important;
  font-size: 0.875rem !important;
  color: #d97706 !important;
}
.search-bar {
  align-items: flex-start;
  align-self: stretch;
  border-radius: 8px;
  border: 1px solid var(--Neutral-Light-Grey, #f2f3f3);
  background: var(--Neutral-White, #fff);
  box-shadow: 0 4px 10px 0 rgba(227, 229, 235, 0.6);
  display: flex;
  flex-direction: column;
  overflow: hidden;
  font-family: Poppins, sans-serif;
  padding: 20px 24px;
}

.search-entry {
  display: flex;
  align-items: center;
  gap: 16px;
  width: 100%;
}

.search-input {
  font-size: 16px;
  color: var(--Neutral-Black, #383a3e);
  font-weight: 400;
  line-height: 28px;
  width: 100%;
  min-width: 240px;
  border: none;
  outline: none;
}

.jurisdiction-controls {
  display: flex;
  margin-top: 16px;
  align-items: center;
  gap: 24px;
  font-size: 14px;
  font-weight: 500;
  line-height: 1;
}

.jurisdiction-button {
  border-radius: 4px;
  display: flex;
  align-items: center;
  gap: 4px;
  color: var(--Neutral-Dark-Grey, #86888d);
  padding: 2px 8px;
  background: none;
  border: none;
  cursor: pointer;
}

.jurisdiction-icon {
  width: 18px;
  height: 18px;
  object-fit: contain;
}

.file-button {
  text-decoration: underline;
  border-radius: 4px;
  background: var(--Neutral-Light-Grey, #f2f3f3);
  color: var(--Status-Blue, #1890ff);
  padding: 2px 8px;
  border: none;
  cursor: pointer;
}

.visually-hidden {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}

@media (max-width: 991px) {
  .search-bar {
    padding: 0 20px;
  }

  .search-entry,
  .search-input {
    max-width: 100%;
  }
}

.link-buttons {
  position: sticky;
  position: -webkit-sticky;
  top: 10px;
  display: flex;
  max-width: 136px;
  margin-top: 48px;
  flex-direction: column;
  color: #383a3e;
  font: 500 16px Poppins, sans-serif;
}

.link-button {
  display: flex;
  align-items: center;
  width: 100%;
  gap: 8px;
  padding: 8px 16px 8px 10px;
  border-radius: 4px;
  border: 1px solid #d1d2d5;
  background-color: #fff;
  text-align: left;
  cursor: pointer;
  overflow: hidden;
  white-space: nowrap;
}

.link-button:not(:first-child) {
  margin-top: 16px;
}

.link-button--active {
  border-color: #1890ff;
  background-color: rgba(24, 144, 255, 0.1);
}

.link-button--add {
  background-color: #f2f3f3;
  color: #0e4485;
}

.link-button__icon {
  width: 20px;
  height: 20px;
  object-fit: contain;
}

.link-button__text {
  flex-grow: 1;
}

.rotate-180 {
  transform: rotate(180deg);
  transition: transform 0.3s ease;
}

.page-content {
  padding: 0px;
  overflow-x: hidden;
}

.overlay-container {
  width: 25%;
  position: absolute;
  top: 8.7vh;
  right: 0;
  z-index: 1000;
  height: 100vh;
}

.breadcrumb_header {
  display: flex;
  justify-content: space-between;
  height: 68px;
  background: #fff;
  /* padding: 16px 20px; */
  align-items: center;
}

.breadcrumb {
  display: flex;
  align-items: center;
  margin: auto 0;
}
.breadcrumb-text {
  color: var(--Neutral-Dark-Grey, #86888d);
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
}
.open_sidebar {
  display: flex;
  padding: 4px 16px;
  justify-content: center;
  align-items: center;
  gap: 8px;

  border-radius: 4px;
  background: var(--Primary-Blue, #0e4485);
}

.open_sidebar span {
  color: #fff;
  display: flex;
  /* Ensure icon is aligned vertically */
  align-items: center;
  /* Align icon vertically within span */
}

.open_sidebar p {
  color: #fff !important;
  margin: 0;
  /* Remove any default margin to align the text properly */
  line-height: 1;
  /* Ensure text has the same line height as the icon */
  display: flex;
  align-items: center;
  /* Align text vertically */
}

.divider {
  display: inline-block;
  margin: 0px 16px;
  width: 1px;
  height: 32px;
  background-color: var(--mid-grey);
}

.back {
  color: var(--primary) !important;
  font-weight: 500;
}
</style>
<style scoped>
.content {
  border-radius: 8px;
  background: var(--Neutral-White, #fff);
  box-shadow: 0 2px 4px 0 rgba(170, 171, 175, 0.6);
  display: flex;
  flex-direction: column;
  padding: 22px 24px;
  margin-top: 2rem;
}

@media (max-width: 991px) {
  .content {
    padding: 0 20px;
  }
}

.intro,
.question-section,
.answer-section,
.discussion,
.doctorine,
.citations,
.legal-references {
  max-width: 100%;
}

.quillEditor .ql-toolbar.ql-snow {
  border-radius: 8px 0px !important;
  border-bottom: 1px solid #d1d2d5 !important;
  background: #f2f3f3 !important;
}

.legal-references {
  margin-bottom: 5rem;
}

.title {
  color: var(--Primary-Blue, #0e4485) !important;
  font: 500 16px/28px Poppins, sans-serif;
}

.separator {
  background: var(--mid-grey);
  height: 1px;
  width: 100%;
}

.question-section .tabs,
.answer-section .answer-header,
.legal-references {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}

.result-tabs {
  display: flex;
  width: 100%;
  background: var(--Neutral-Light-Grey, #f2f3f3);
  align-items: center;
}

.question .button,
.actions .button,
.tab {
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: 14px;
  border-radius: 4px;
}

h6.title.answer-title.case-title {
  color: var(--Primary-Blue, #0e4485) !important;
  margin: 0px;
}

.img {
  aspect-ratio: 1/1;
  width: 18px;
  object-fit: contain;
}

.question-description,
.answer-text {
  margin-top: 4px;
  color: var(--Neutral-Dark-Grey, #86888d) !important;
  font: 400 14px/24px Poppins, sans-serif;
}

.doctorine-text {
  color: var(--Neutral-Dark-Grey, #86888d);

  /* Button */
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.text-link {
  color: var(--Status-Blue, #1890ff);
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 142.857% */
  text-decoration-line: underline;
}

.index-link {
  color: var(--Status-Blue, #1890ff);
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 142.857% */
}

.answer-title,
.reference-title {
  color: var(--Primary-Blue, #0e4485) !important;
  font-size: 16px;
  line-height: 28px;
}

.actions .button {
  border: 1px solid var(--Neutral-Mid-Grey, #d1d2d5);
  background: var(--Neutral-White, #fff);
  color: var(--Primary-Dark-Blue, #000046) !important;
}

.actions .button-primary {
  background: var(--Primary-Blue, #0e4485);
  color: var(--Neutral-White, #fff) !important;
}

.actions .button,
.actions .button-primary {
  padding: 2px 8px;
}

.details .detail {
  color: var(--Neutral-Dark-Grey, #86888d) !important;
  font-size: 14px;
}

.detail-title {
  color: var(--Primary-Blue, #0e4485) !important;
  font-size: 16px;
}

.detail-text {
  margin-top: 4px;
  color: var(--Neutral-Dark-Grey, #86888d) !important;
}

.link {
  color: var(--Status-Blue, #1890ff) !important;
}

.results {
  background: var(--Neutral-Interface-Grey, #fafbfc);
  border: 1px solid var(--Neutral-Light-Grey, #f2f3f3);
  border-radius: 8px;
}

.result-tabs .tab {
  background: var(--Neutral-Light-Grey, #f2f3f3);
  color: var(--Neutral-Dark-Grey, #86888d);
  padding: 8px 16px;
  font-weight: 500;
  border-radius: 0px !important;
}

.result-tabs .tab.active {
  border-bottom: 2px solid var(--Primary-Blue, #0e4485);
  color: var(--Primary-Blue, #0e4485) !important;
}

.case-result {
  border: 1px solid var(--Neutral-Light-Grey, #f2f3f3);
  border-radius: 8px;
  background: var(--Neutral-White, #fff);
  padding: 20px 16px;
  margin-top: 20px;
}

.case-title {
  font-size: 16px;
  color: var(--Neutral-Black, #383a3e) !important;
  font-weight: 500;
}

.case-details .meta {
  display: flex;
  align-items: center;
}

.case-details .meta img {
  margin-right: 4px;
}

.case-overview,
.case-analysis p {
  font-size: 14px;
  color: var(--Neutral-Dark-Grey, #86888d) !important;
  line-height: 24px;
}

.relevancy {
  display: flex;
  align-items: center;
  color: var(--Status-Green, #52c41a) !important;
}

.relevancy img {
  margin-right: 4px;
}

.actions {
  display: flex;
  gap: 2px;
}
</style>

<style scoped>
.follow-up-question {
  justify-content: center;
  border-radius: 16px;
  border: 1px solid var(--Primary-Blue, #0e4485);
  background: var(--Neutral-White, #fff);
  box-shadow: 0 2px 10px 0 rgba(14, 68, 133, 0.6);
  z-index: 10;
  display: flex;
  width: 74%;
  gap: 8px;
  flex-wrap: wrap;
  padding: 12px 20px;
  align-items: center;
  position: fixed;
  bottom: 20px;
}

.input-field {
  align-self: stretch;
  min-width: 240px;
  flex: 1;
  padding: 10px 16px 10px 0;
  font: 400 16px Poppins, sans-serif;
  border: none;
  outline: none;
  background: transparent;
}

.follow-up-actions {
  display: flex;
  padding-right: 20px;
  align-items: center;
  justify-content: start;
  height: 100%;
  width: 56px;
}

.submit-button {
  border-radius: 4px;
  align-self: stretch;
  display: flex;
  width: 36px;
  align-items: center;
  justify-content: center;
  margin: auto 0;
  padding: 6px;
  background: none;
  border: none;
  cursor: pointer;
}

.submit-icon {
  aspect-ratio: 1;
  object-fit: contain;
  object-position: center;
  width: 24px;
  align-self: stretch;
}

.visually-hidden {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}

@media (max-width: 991px) {
  .follow-up-question,
  .input-field {
    max-width: 100%;
  }
}
</style>

<style>
#dropdown-right .dropdown-toggle:focus,
#dropdown-right .dropdown-toggle:active,
#dropdown-right .dropdown-toggle.show,
#dropdown-right .dropdown-menu {
  outline: none;
  box-shadow: none;
}

#dropdown-right .dropdown-item:active,
#dropdown-right .dropdown-item:focus {
  color: black !important;
  background-color: transparent !important;
}

.case-summary {
  border-collapse: collapse; /* Ensure borders are collapsed */
}

.case-summary th {
  padding: 9px 20px 9px 16px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex: 1 0 0;
  border: 1px solid var(--Neutral-Dark-Grey, #86888d);
  border-radius: 8px;
}

.case-summary td {
  padding: 9px 4px 9px 16px;
  align-items: center;
  gap: 8px;
  flex: 1 0 0;
  align-self: stretch;
  border-radius: 8px;
  border: 1px solid var(--Neutral-Dark-Grey, #86888d);
  border-bottom: 1px solid var(--Neutral-Dark-Grey, #86888d) !important;
}

.claims-container {
  border-radius: 8px;
  background: #fff;
  box-shadow: 0 4px 10px rgba(227, 229, 235, 0.6);
  display: flex;
  flex-direction: column;
  font: 400 14px/1.5 Poppins, sans-serif;
  padding: 22px 24px;
  margin-bottom: 2rem;
  transition: height 0.3s ease;
  cursor: pointer;
}

.claims-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 40px;
  /* flex-wrap: wrap; */
}

.title-group {
  display: flex;
  min-width: 240px;
  align-items: center;
  gap: 8px;

  line-height: 28px;
}
.overlay-container {
  width: 25%;
  position: fixed;
  top: 8.7vh;
  right: 0;
  z-index: 1000;

  height: 100vh;
}
.primary-title {
  color: #0e4485;
  font-family: Poppins;
  font-size: 1rem !important;
  font-style: normal;
  font-weight: 500;
  line-height: 1.75rem;
}

.secondary-title {
  color: #383a3e;
  font-family: Poppins;
  font-size: 1rem;
  font-style: normal;
  font-weight: 500;
  line-height: 1.75rem;
}

.action-buttons {
  display: flex;
  align-items: center;
  gap: 8px;
}

.action-btn {
  display: flex;
  align-items: center;
  gap: 4px;
  border-radius: 4px;
  padding: 2px 8px;
  font-size: 14px;
  cursor: pointer;
  border: none;
  background: none;
}

.dropdown-text {
  color: var(--Neutral-Black, #383a3e);
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 142.857% */
}

.copy-btn {
  color: #0e4485;
}

.export-btn {
  background: #0e4485;
  color: #fff;
}

.icon {
  width: 18px;
  object-fit: contain;
}

.action-icon {
  width: 18px;
  height: 18px;
}

.claims-content {
  margin: 32px 0;

  color: var(--Neutral-Dark-Grey, #86888d) !important;
  font-family: Poppins, sans-serif !important;
  font-size: 16px !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: 1.5rem !important;
}

.claims-table {
  border: 1px solid #86888d;
  border-radius: 8px;
  overflow: hidden;
}

.table-header {
  display: flex;
  background: #f2f3f3;
  border-bottom: 1px solid #86888d;
}

.column-title {
  flex: 1;
  min-width: 240px;
  padding: 9px 16px;
  margin: 0;
  font-size: 16px;
  font-weight: 600;
  color: #383a3e;
}

.column-title:first-child {
  border-right: 1px solid #86888d;
}

.table-row {
  display: flex;
  border-bottom: 1px solid #86888d;
}

.row-label {
  flex: 1;
  min-width: 240px;
  padding: 9px 16px;
  border-right: 1px solid #86888d;
}

.row-content {
  flex: 1;
  min-width: 240px;
  padding: 9px 16px;
}

.wrapper-chatbox {
  position: fixed;
  right: 2rem;

  width: 80%;
  z-index: 30;
  bottom: 2rem;
}

.skeleton-loader {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.skeleton-item {
  border-radius: 12px;
  background-color: rgba(243, 243, 243, 1);
  min-height: 28px;
}

.skeleton-item-large {
  width: 535px;
  max-width: 100%;
}

.skeleton-item-full {
  width: 100%;
  margin-top: 8px;
}
</style>

<style scoped>
.action-buttons {
  display: flex;
  gap: 16px;
  font: 500 14px/1 Poppins, sans-serif;
}

.action-button {
  display: flex;
  align-items: center;
  gap: 4px;
  padding: 4px 8px;
  border-radius: 4px;
  cursor: pointer;
}

.copy-button,
.export-button {
  display: flex;
  align-items: center;
  gap: 4px;
  border-radius: 4px;
  padding: 2px 8px;
  border: none;
  cursor: pointer;
}

.copy-button {
  color: #0e4485;
  background: transparent;
}

.export-button {
  color: #fff;
  background: #0e4485;
}

.claims-container {
  border-radius: 8px;
  background: #fff;
  box-shadow: 0 4px 10px rgba(227, 229, 235, 0.6);
  display: flex;
  flex-direction: column;
  font: 400 14px/1.5 Poppins, sans-serif;
  padding: 22px 24px;
  margin-bottom: 2rem;
  transition: height 0.3s ease;
  cursor: pointer;
}

.claims-header {
  display: flex;
  align-items: start;
  justify-content: space-between;
  gap: 40px;
  flex-wrap: wrap;
}

.title-group {
  display: flex;

  min-width: 240px;
  align-items: start;
  gap: 8px;
  max-width: 80%;
  line-height: 28px;
}
.overlay-container {
  width: 25%;
  position: fixed;
  top: 8.7vh;
  right: 0;
  z-index: 1000;

  height: 100vh;
}
.primary-title {
  color: #0e4485;
  font-family: Poppins;
  text-wrap: nowrap;
  font-size: 1rem !important;
  font-style: normal;
  font-weight: 500;
  line-height: 1.75rem;
}

.secondary-title {
  color: #383a3e;
  font-family: Poppins;
  font-size: 1rem !important;
  font-style: normal;
  font-weight: 500;
  line-height: 1.75rem;
}

.action-buttons {
  display: flex;
  align-items: center;
  gap: 16px;
}

.action-btn {
  display: flex;
  align-items: center;
  gap: 4px;
  border-radius: 4px;
  padding: 2px 8px;
  font-size: 14px;
  cursor: pointer;
  border: none;
  background: none;
}

.copy-btn {
  color: #0e4485;
}

.export-btn {
  background: #0e4485;
  color: #fff;
}

.action-icon {
  width: 18px;
  height: 18px;
}

.claims-content {
  margin: 32px 0;

  color: var(--Neutral-Dark-Grey, #86888d) !important;
  font-family: Poppins, sans-serif !important;
  font-size: 16px !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: 1.5rem !important;
}

.claims-table {
  border: 1px solid #86888d;
  border-radius: 8px;
  overflow: hidden;
}

.table-header {
  display: flex;
  background: #f2f3f3;
  border-bottom: 1px solid #86888d;
}

.column-title {
  flex: 1;
  min-width: 240px;
  padding: 9px 16px;
  margin: 0;
  font-size: 16px;
  font-weight: 600;
  color: #383a3e;
}

.skeleton-loader {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.skeleton-item {
  border-radius: 12px;
  background-color: rgba(243, 243, 243, 1);
  min-height: 28px;
}

.skeleton-item-large {
  width: 535px;
  max-width: 100%;
}

.skeleton-item-full {
  width: 100%;
  margin-top: 8px;
}

@media (max-width: 991px) {
  .skeleton-item-full {
    max-width: 100%;
  }
}

.uploaded-summary {
  margin-top: 1.5rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 0.5rem;
  align-self: stretch;
}

.wrapper-chatbox {
  position: fixed;
  right: 2rem;

  width: 80%;
  z-index: 30;
  bottom: 2rem;
}

.summary-header {
  display: flex;
  align-items: center;
  gap: 0.625rem;
  align-self: stretch;
}

.summary-title {
  color: var(--Neutral-Black, #383a3e);
  margin: 0 0 !important;
  font-size: 1rem !important;
  font-style: normal;
  font-weight: 500;
  line-height: 1.75rem;
}

.files-uploaded {
  color: var(--Status-Blue, #1890ff);
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 500;
  line-height: 1.25rem;
  text-decoration-line: underline;
  cursor: pointer;
}
.summary-text {
  color: var(--Neutral-Dark-Grey, #86888d);
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5rem;
}

.summary-footer {
  display: flex;
  padding: 0.125rem 0.5rem;
  justify-content: center;
  align-items: center;
  gap: 0.25rem;
}

.files-uploaded:focus {
  color: #383a3e;
}

.page-content {
  padding: 0px;
  overflow-x: hidden;
}

.overlay-container {
  width: 25%;
  position: absolute;
  top: 8.7vh;
  right: 0;
  z-index: 1000;
  height: 100vh;
}

.breadcrumb_header {
  display: flex;
  justify-content: space-between;
  padding: 5px;
  background: #fff;
}

.breadcrumb {
  display: flex;
  align-items: center;
  margin: auto 0;

  span {
    font-size: 14px;
    color: var(--dark-grey) !important;
  }
}

.breadcrumb-title {
  margin: 0 0 0 16px;
  font-size: 24px;
  font-weight: 600;
  padding-left: 10px;
}

.open_sidebar {
  height: 40px;
  background: var(--primary);
  display: inline-flex;
  padding: 4px 16px;
  justify-content: center;
  align-items: center;
  /* Ensure vertical alignment */
  gap: 8px;
  border-radius: 4px;
}

.open_sidebar span {
  color: #fff;
  display: flex;
  /* Ensure icon is aligned vertically */
  align-items: center;
  /* Align icon vertically within span */
}

.open_sidebar p {
  color: #fff !important;
  margin: 0;
  /* Remove any default margin to align the text properly */
  line-height: 1;
  /* Ensure text has the same line height as the icon */
  display: flex;
  align-items: center;
  /* Align text vertically */
}

.divider {
  display: inline-block;
  margin: 0px 16px;
  width: 1px;
  height: 32px;
  background-color: var(--mid-grey);
}

.back {
  color: var(--primary) !important;
  font-weight: 500;
}
</style>
<style scoped>
.content {
  border-radius: 8px;
  background: var(--Neutral-White, #fff);
  box-shadow: 0 2px 4px 0 rgba(170, 171, 175, 0.6);
  display: flex;
  flex-direction: column;
  padding: 22px 24px;
}

@media (max-width: 991px) {
  .content {
    padding: 0 20px;
  }
}

.intro,
.question-section,
.answer-section,
.details,
.legal-references {
  max-width: 100%;
}

.legal-references {
  margin-bottom: 5rem;
}

.title {
  color: var(--Primary-Blue, #0e4485) !important;
  font: 500 16px/28px Poppins, sans-serif;
}

.question-section .tabs,
.answer-section .answer-header,
.legal-references {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}

.result-tabs {
  display: flex;
  width: 100%;
  background: var(--Neutral-Light-Grey, #f2f3f3);
  align-items: center;
}

.question .button,
.actions .button,
.tab {
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: 14px;
  border-radius: 4px;
}

h6.title.answer-title.case-title {
  color: var(--Primary-Blue, #0e4485) !important;
  margin: 0px;
}

.img {
  aspect-ratio: 1/1;
  width: 18px;
  object-fit: contain;
}

.question-description,
.answer-text {
  margin-top: 4px;
  color: var(--Neutral-Dark-Grey, #86888d) !important;
  font: 400 14px/24px Poppins, sans-serif;
}

.answer-title,
.reference-title {
  color: var(--Primary-Blue, #0e4485) !important;
  font-size: 16px;
  line-height: 28px;
}

.actions .button {
  border: 1px solid var(--Neutral-Mid-Grey, #d1d2d5);
  background: var(--Neutral-White, #fff);
  color: var(--Primary-Dark-Blue, #000046) !important;
}

.actions .button-primary {
  background: var(--Primary-Blue, #0e4485);
  color: var(--Neutral-White, #fff) !important;
}

.actions .button,
.actions .button-primary {
  padding: 2px 8px;
}

.details .detail {
  color: var(--Neutral-Dark-Grey, #86888d) !important;
  font-size: 14px;
}

.detail-title {
  color: var(--Primary-Blue, #0e4485) !important;
  font-size: 16px;
}

.detail-text {
  margin-top: 4px;
  color: var(--Neutral-Dark-Grey, #86888d) !important;
}

.link {
  color: var(--Status-Blue, #1890ff) !important;
}

.results {
  background: var(--Neutral-Interface-Grey, #fafbfc);
  border: 1px solid var(--Neutral-Light-Grey, #f2f3f3);
  border-radius: 8px;
}

.result-tabs .tab {
  background: var(--Neutral-Light-Grey, #f2f3f3);
  color: var(--Neutral-Dark-Grey, #86888d);
  padding: 8px 16px;
  font-weight: 500;
  border-radius: 0px !important;
}

.result-tabs .tab.active {
  border-bottom: 2px solid var(--Primary-Blue, #0e4485);
  color: var(--Primary-Blue, #0e4485) !important;
}

.case-result {
  border: 1px solid var(--Neutral-Light-Grey, #f2f3f3);
  border-radius: 8px;
  background: var(--Neutral-White, #fff);
  padding: 20px 16px;
  margin-top: 20px;
}

.case-title {
  font-size: 16px;
  color: var(--Neutral-Black, #383a3e) !important;
  font-weight: 500;
}

.case-details .meta {
  display: flex;
  align-items: center;
}

.case-details .meta img {
  margin-right: 4px;
}

.case-overview,
.case-analysis p {
  font-size: 14px;
  color: var(--Neutral-Dark-Grey, #86888d) !important;
  line-height: 24px;
}

.relevancy {
  display: flex;
  align-items: center;
  color: var(--Status-Green, #52c41a) !important;
}

.relevancy img {
  margin-right: 4px;
}

.actions {
  display: flex;
  gap: 2px;
}
</style>

<style scoped>
.follow-up-question {
  justify-content: center;
  border-radius: 16px;
  border: 1px solid var(--Primary-Blue, #0e4485);
  background: var(--Neutral-White, #fff);
  box-shadow: 0 2px 10px 0 rgba(14, 68, 133, 0.6);
  z-index: 10;
  display: flex;
  width: 74%;
  gap: 8px;
  flex-wrap: wrap;
  padding: 12px 20px;
  align-items: center;
  position: fixed;
  bottom: 20px;
}

.input-field {
  align-self: stretch;
  min-width: 240px;
  flex: 1;
  padding: 10px 16px 10px 0;
  font: 400 16px Poppins, sans-serif;
  border: none;
  outline: none;
  background: transparent;
}

.follow-up-actions {
  display: flex;
  padding-right: 20px;
  align-items: center;
  justify-content: start;
  height: 100%;
  width: 56px;
}

.submit-button {
  border-radius: 4px;
  align-self: stretch;
  display: flex;
  width: 36px;
  align-items: center;
  justify-content: center;
  margin: auto 0;
  padding: 6px;
  background: none;
  border: none;
  cursor: pointer;
}

.submit-icon {
  aspect-ratio: 1;
  object-fit: contain;
  object-position: center;
  width: 24px;
  align-self: stretch;
}

.visually-hidden {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}

@media (max-width: 991px) {
  .follow-up-question,
  .input-field {
    max-width: 100%;
  }
}
</style>
